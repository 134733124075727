<template>
  <div class="page">
    <Navbar title="结果反馈" type="SMP" />
    <van-form @submit="submit" class="form">
      <van-cell-group>
        <van-field
          name="result"
          label="咨询结果"
          :rules="[
            {
              required: true,
              message: '请选择咨询结果',
            },
          ]"
        >
          <template #input>
            <van-radio-group
              v-model="consult.result"
              :checked-color="COLOR"
              direction="horizontal"
            >
              <van-radio name="SUC">成功</van-radio>
              <van-radio name="FAL">失败</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-model="consult.resultDesc"
          rows="2"
          autosize
          label="结果描述"
          type="textarea"
          maxlength="300"
          placeholder="请输入结果描述"
          show-word-limit
          :rules="[
            {
              required: true,
              message: '请填写结果描述',
            },
          ]"
        />
      </van-cell-group>
      <van-row>
        <van-col offset="4" span="16" class="subbtn">
          <van-button
            icon="passed"
            size="small"
            color="#00CC99"
            class="btn"
            native-type="submit"
          >
            提交保存
          </van-button>
        </van-col>
      </van-row>
    </van-form>
    <Loading :show="loadingShow" />
  </div>
</template>
<script>
import { Form, CellGroup, Field, RadioGroup, Radio } from 'vant'
import Navbar from '../../module/common/Navbar.vue'
import Loading from '../../module/common/Loading.vue'
export default {
  components: {
    Navbar: Navbar,
    Loading: Loading,
    [Form.name]: Form,
    [CellGroup.name]: CellGroup,
    [Field.name]: Field,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio
  },
  data () {
    return {
      loadingShow: false,
      code: '',
      consult: { result: 'FAL', resultDesc: '' }
    }
  },
  mounted () {
    var query = this.$route.query
    this.code = query.code
    this.retrieveConsultResult()
  },
  methods: {
    async retrieveConsultResult () {
      var pd = { code: this.code }
      const { data: res } = await this.$http.post(this.BMS_URL + '/overall/consult/retrieveConsultResult', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.consult.result = res.data.result
        this.consult.resultDesc = res.data.resultDesc
      }
    },
    async submit () {
      var pd = { code: this.code, result: this.consult.result, resultDesc: this.consult.resultDesc }
      const { data: res } = await this.$http.post(this.BMS_URL + '/overall/consult/updateConsultResult', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.$router.go(-1)
      } else {
        this.$dialog.alert({
          title: '提示信息',
          message: '更新失败:' + res.msg
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.form {
  margin-top: 50px;
  padding: 0px 5px;
  .btn {
    margin-top: 10px;
  }
}
</style>
